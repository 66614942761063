<template>
  <div class="user-box">
    <search-form @search="search"></search-form>
    <detail-table :list="list"></detail-table>
    <!-- 分页管理 -->
    <el-pagination
      background
      :page-sizes="pageSizes"
      :page-size="10"
      :current-page="currentPage"
      layout="total, sizes, prev, pager, next"
      :total="page.allNum"
      @size-change="changeSize"
      @current-change="changePage"
    ></el-pagination>
  </div>
</template>

<script>
import detailTable from './components/detail-table';
import searchForm from './components/search-form';

import service from './api';
import pagination from '@/mixins/pagination';
export default {
  mixins: [pagination],
  data() {
    return {
      list: [],
      page: {},
      searchData: {}
    };
  },
  components: {
    detailTable,
    searchForm
  },
  methods: {
    init() {
      let initParams = { pageNum: 1 };
      this.getList(initParams);
    },
    getList(params) {
      let { pageNum = 1 } = params;
      this.currentPage = pageNum;

      return service.getList(params).then(res => {
        let { list = [], page = {} } = res;
        list.forEach((item, index) => {
          item.tempId = (params.pageNum - 1) * page.pageSize + index + 1;
        });
        this.list = list;
        this.page = page;
      });
    },
    // 搜索
    search(data) {
      let params = { ...data, pageNum: 1 };
      this.searchData = data;
      return this.getList(params);
    }
  },
  mounted() {
    this.init();
  }
};
</script>

<style lang="scss">
@media screen and (max-width: 480px) {
  .el-pagination{
    overflow: auto!important;
  }
}
</style>