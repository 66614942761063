import API from "@/api";

export default {
  getList: (params) => {
    return API.requestGet({
      params,
      url: "report/reportDetail",
      auth: true,
    });
  },
};
