/**
 * 分页功能混入
 * 使用需要规定获取表格数据的方法命名为  @getList
 */

export default {
  data() {
    return {
      currentPage: 1,
      pageSize:10,
      //排序
      orderData:{}
    };
  },
  computed: {
    // 分页选项
    pageSizes() {
      let { allPage = 1, pageSize = 10, allNum = 1 } = this.page;
      let sizeList = [];
      let num = Math.ceil(allNum / 10);
      // for (let i = 1; i <= allPage; i++) {
      //   sizeList.push(i * pageSize);
      // }
      for (let i = 1; i <= num; i++) {
        sizeList.push(i * 10);
      }
      return sizeList;
    }
  },
  methods: {
    // 更改分页大小
    changeSize(size) {
      this.pageSize = parseInt(size);
      let { searchData = {}, orderData = {} } = this;
      let param = {
        pageNum: 1,
        pageSize: parseInt(size)
      };
      return this.getList({
        ...searchData,
        ...param,
        ...orderData
      });
    },
    // 切换分页
    changePage(page) {
      this.currentPage = page;
      let { searchData = {}, orderData = {} } = this;
      let { pageSize = 10 } = this.page;
      let param = {
        pageNum: page,
        pageSize
      };
      return this.getList({
        ...searchData,
        ...param,
        ...orderData
      });
    },
    //排序
    handleSort(data){
      let { searchData = {}} = this;
      this.orderData = {
        orderKey:data.prop,
        isAsc:data.order == 'ascending'? true : false
      }
      this.getList({pageNum: this.currentPage, ...this.orderData, ...searchData} );
    }
  }
};
